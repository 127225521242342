<template>
  <div class="row px-md-4">
    <div class="col-12 col-sm-12">
      <h2 class="main-title">Management Report</h2>
    </div>
    <div class="col-12 col-sm-12">
      <div class="card">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="box-alert-message float-right">
              You have
              <strong
                ><strong
                  v-if="unused_lines.lines && unused_lines.lines.length"
                  >{{ unused_lines.lines.length }}</strong
                >
                lines</strong
              >
              that are unassigned.
              <button class="link" @click.prevent="showUnLines()">
                Review
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
            class="col-12 col-sm-12 d-flex flex-column flex-md-row align-items-center justify-content-between"
          >
            <span class="buttons-mangers-two">
              <button
                class="btn button-expertel"
                @click.prevent="showAddCostCentre"
              >
                Add/Edit a Manager
              </button>

              <select
                class="selectReport"
                v-if="list_report && list_report.length > 0"
                v-model="selectedOption"
                @change="changeReport"
              >
                <option
                  v-for="(item, index) in list_report"
                  :key="index"
                  v-bind:value="item.id"
                  v-bind:selected="index === 0 ? true : false"
                >
                  {{ format(item.report_date) }}
                </option>
              </select>
            </span>

            <button
              href="#"
              class="link color-orange mt-3 mt-md-0"
              @click="showInstructions"
            >
              Step by step instructions
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <hr class="h-3" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="overflow-auto mb-3 mb-md-0">
              <table class="table table-group-managers">
                <thead>
                  <tr>
                    <th class="w-50px">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="selectAll"
                      />
                    </th>
                    <th>Group Name</th>
                    <th># of Users</th>
                    <th>Email Recipient</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,
                    key) in manager_centres_data_filtered.manager_centres"
                    :key="key"
                  >
                    <td class="w-50px">
                      <span>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          :value="item.centre_id"
                          v-model="selected"
                        />
                      </span>
                    </td>
                    <td
                      @click="
                        showUnassignedDetail(item.centre_id, item.centre_name)
                      "
                    >
                      <a
                        @click.prevent="showUnassignedDetail(item.centre_id)"
                        href="#"
                        >{{ item.centre_name }}</a
                      >
                    </td>
                    <td @click="showUnassignedDetail(item.centre_id)">
                      {{ item.count }}
                    </td>
                    <td @click="showUnassignedDetail(item.centre_id)">
                      <ul class="managers_email_recipient">
                        <li v-for="(reg, keyReg) in item.admins" :key="keyReg">
                          {{ reg.email }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          class="row"
          v-if="
            manager_centres_data_filtered.manager_centres &&
              manager_centres_data_filtered.manager_centres.length > 0
          "
        >
          <div class="col-12 col-sm-12 text-center">
            <button
              class="button-expertel btnDownload"
              @click="downloadPdfReport()"
            >
              Download PDF Report
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 mt-4">
      <div class="card">
        <div class="row">
          <div
            class="col-12 col-sm-12 d-flex flex-column flex-md-row align-items-center justify-content-between"
          >
            <h2 class="mb-3 mb-md-0">Report Recipients</h2>
            <div class="d-flex">
              <button
                class="btn button-expertel mr-3"
                @click.prevent="showDownloadRecipients"
              >
                <i class="far fa-file-download mr-2"></i> Download List
              </button>
              <button
                class="btn button-expertel"
                @click.prevent="showUploadRecipients"
              >
                <i class="far fa-file-upload mr-2"></i> Upload List
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
            class="col-12 col-sm-12 d-flex align-items-center justify-content-center justify-content-md-end"
          >
            <button
              href="#"
              class="link color-orange"
              @click="showInstructionsRecipients"
            >
              Step by step instructions
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <hr class="h-3" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <div>
              <ul class="list-table">
                <li class="list-table-row list-table-header">
                  <ul>
                    <li class="list-table-column">Name</li>
                    <li class="list-table-column">Email</li>
                  </ul>
                </li>

                <li
                  class="list-table-row"
                  v-for="item in recipients_centres_data"
                  :key="item.id"
                >
                  <ul>
                    <li class="list-table-column">{{ item.name }}</li>
                    <li class="list-table-column">{{ item.email }}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- [[ INSTRUCTIONS MANAGER MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="show_instrictions === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showInstructions()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center mb-3">
              <strong>Instructions to add/edit Managers</strong>
            </h3>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 1: </strong>
              <a
                href="#"
                class="link color-gray"
                @click.prevent="showAddCostCentre"
                >Download</a
              >
              the current Managers list.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 2: </strong> Add phone lines being
              tracked in first column
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 3: </strong>
              <a
                href="#"
                class="link color-gray"
                @click.prevent="showAddCostCentre"
                >Upload</a
              >
              the updated list.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 4: </strong> Refresh browser to
              visualise modifications.
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ INSTRUCTIONS MANAGER MODAL ]] -->

    <!-- [[ INSTRUCTIONS RECIPIENTS MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="show_instrictions_recipients === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showInstructionsRecipients()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center mb-3">
              <strong>Instructions to add/edit Recipients</strong>
            </h3>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 1: </strong>
              <a
                href="#"
                class="link color-gray"
                @click.prevent="showDownloadRecipients"
                >Download</a
              >
              the current Recipientss list.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 2: </strong> Add phone lines being
              tracked in first column
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 3: </strong>
              <a
                href="#"
                class="link color-gray"
                @click.prevent="showUploadRecipients()"
                >Upload</a
              >
              the updated list.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 4: </strong> Refresh browser to
              visualise modifications.
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ INSTRUCTIONS RECIPIENTS MODAL ]] -->

    <!-- [[ ADD ADD/EDIT COST CENTRE MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="add_costCentre === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showAddCostCentre()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center">
              <strong>Add/Edit Cost Centre List</strong>
            </h3>
            <p class="text-center my-4 ">
              <strong>Download</strong>
              <br />
              To include a new cost centre or edit an existing one first
              download the current Cost Centre list. <br />
              <a
                :href="manager_centres_data.files.cost_centre_url"
                class="button-expertel py-3 mt-3"
                >Download Current List</a
              >
            </p>
            <hr class="my-4" />
            <p class="text-center mt-4 ">
              <strong>Upload</strong>
              <br />
              After editing upload your file to update current list.
            </p>
            <div class="upload-file">
              <input
                type="file"
                id="file_managers"
                name="file_managers"
                @change.prevent="onChangeManagers()"
                ref="file_managers"
              />
              <button class="btn button-outline-expertel-gray">
                Choose file
              </button>
              <p class="ml-3 mb-0 color-gray" v-if="this.file_managers == ''">
                No file chosen
              </p>
              <p class="ml-3 mb-0 color-gray" v-else>
                {{ file_managers.name }}
              </p>
            </div>
            <p class="text-center ">
              <a
                href="#"
                class="button-expertel py-3 mt-3"
                @click.prevent="uploadCostCentreListManagers()"
                >Upload List
              </a>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ ADD ADD/EDIT COST CENTRE MODAL ]] -->

    <!-- [[ UPLOAD RECIPIENTS LIST MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="upload_recipients === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showUploadRecipients()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center">
              <strong>Upload Report Recipients List</strong>
            </h3>
            <p class="text-center color-gray mt-4 ">
              After editing upload your file to update current list.
            </p>
            <div class="upload-file mt-4">
              <input
                type="file"
                name="file_recipients"
                id="file_recipients"
                @change.prevent="onChangeRecipients()"
                ref="file_recipients"
              />
              <button class="btn button-outline-expertel-gray">
                Choose file
              </button>
              <p class="ml-3 mb-0 color-gray" v-if="this.file_recipients == ''">
                No file chosen
              </p>
              <p class="ml-3 mb-0 color-gray" v-else>
                {{ file_recipients.name }}
              </p>
            </div>
            <p class="text-center ">
              <a
                href="#"
                class="button-expertel py-3 mt-4"
                @click.prevent="uploadCostCentreListRecipients()"
                >Upload Updated List</a
              >
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ UPLOAD RECIPIENTS LIST MODAL ]] -->

    <!-- [[ DOWNLOAD RECIPIENTS MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="show_dlRecipients === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showDownloadRecipients()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center mb-3">
              <strong>Download Report Recipients List</strong>
            </h3>
            <p class="text-center color-gray">
              To include a new Recipient or edit an existing one first download
              the current Recipients list.
            </p>
            <p class="text-center">
              <a
                class="button-expertel"
                :href="manager_centres_data.files.recipient_url"
                >Download Current List</a
              >
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ DOWNLOAD RECIPIENTS MODAL ]] -->

    <!-- [[ UNASSIGNED LINES MODAL ]] -->
    <transition name="fade">
      <div class="modal-expertel container-fluid" v-if="show_unLines === true">
        <div class="modal-container medium">
          <div class="header-modal">
            <button class="close-modal" @click="showUnLines()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h4
              class="d-flex align-items-center font-circular-book font-weight-normal mb-3"
            >
              Unassigned Lines
            </h4>
            <p class="mb-0 color-gray">
              The following lines are not assigned to a Cost Centre
            </p>
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <hr class="h-3 mt-3 mb-4" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <div class="table-fixed-header xh-60vh">
                  <table class="table-2">
                    <thead>
                      <tr>
                        <th class="color-blue">Line User</th>
                        <th class="color-blue">Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in unused_lines.lines" :key="key">
                        <td>{{ item.name }}</td>
                        <td>{{ item.mc_phone_number | phoneNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ UNASSIGNED LINES MODAL ]] -->

    <!-- [[ DETAILS MANAGER MODAL ]] -->
    <transition name="fade">
      <div class="modal-expertel container-fluid" v-if="showDetails === true">
        <div class="modal-container medium">
          <div class="header-modal">
            <button class="close-modal" @click="closeDetails()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h4
              class="d-flex align-items-center font-circular-book font-weight-normal mb-3"
            >
              {{ groupTitle }}
            </h4>
            <p class="mb-0 color-gray">
              The following lines are assigned to this group
            </p>
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <hr class="h-3 mt-3 mb-4" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <div class="table-fixed-header xh-60vh">
                  <table class="table-2">
                    <thead>
                      <tr>
                        <th class="color-blue">Line User</th>
                        <th class="color-blue">Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in detailLines" :key="key">
                        <td>{{ item.name }}</td>
                        <td>{{ item.phone | phoneNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ DETAILS MANAGER MODAL ]] -->
  </div>
</template>

<style lang="scss" scoped>
.box-alert-message {
  font-size: 16px;
}

.button-expertel {
  font-size: 14px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.btnDownload {
  font-size: 16px;
  padding-bottom: 23px;
  padding-top: 23px;
}

.link {
  font-size: 16px;
}
.managers_email_recipient {
  margin: 0px;
  padding: 0px;
}

.managers_email_recipient li {
  list-style: none;
}
.selectReport {
  padding: 16px;
  margin-left: 20px;
  border-radius: 3px;
}

select {
  border-color: #ff502d;
  color: #ff502d;
}
</style>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      unused_lines: [],
      manager_centres_data: [],
      manager_centres_data_filtered: [],
      recipients_centres_data: [],
      detailLines: [],
      selected: [],
      show_instrictions: false,
      show_dlRecipients: false,
      add_costCentre: false,
      upload_recipients: false,
      show_instrictions_recipients: false,
      show_unLines: false,
      showDetails: false,
      file_managers: "",
      file_recipients: "",
      centresIdUnassigned: "",
      groupTitle: "",
      reports: [],
      selectedOption: ""
    };
  },
  async created() {
    const self = this;
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }

    this.getManagerCentresAdmins();

    let show_report_id = "";
    if (this.$route.params.id) {
      show_report_id = this.$route.params.id;
    } else {
      show_report_id = this.id_report;
    }
    self.getSubReportsManagers(show_report_id);
    self.selectedOption = show_report_id;

    //GET unassigned list
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/manager_centres/0`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.unused_lines = response.data;
      })
      .catch(error => this.makeToast("ERROR", error, "danger"));

    //GET list report
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/reports`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.reports = response.data.sort(
          (a, b) => new Date(b.report_date) - new Date(a.report_date)
        );
      })
      .catch(error => this.makeToast("ERROR",error, "danger"));
  },
  methods: {
    async getManagerCentresAdmins() {
      const self = this;

      if (this.login_data !== undefined || this.login_data.success === false) {
        if (!this.login_data.success) {
          this.$router.push("/");
        }
      } else {
        this.$router.push("/");
      }
      //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/manager_centres`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.manager_centres_data = response.data;

          //Get all admins
          self.recipients_centres_data = [];
          self.manager_centres_data.manager_centres.forEach(function(value) {
            value.admins.forEach(function(value_data) {
              if (value_data.name != "") {
                self.recipients_centres_data.push({
                  name: value_data.name,
                  email: value_data.email
                });
              }
            });
          });
        })
        .catch(error => (error.response.status===403)?this.makeToast("ERROR","You need to upgrade your billing plan.", "danger"):this.makeToast("ERROR",error, "danger"));
    },
    showAddCostCentre() {
      this.add_costCentre = !this.add_costCentre;
    },
    showInstructions() {
      this.show_instrictions = !this.show_instrictions;
    },
    showDownloadRecipients() {
      this.show_dlRecipients = !this.show_dlRecipients;
    },
    showUploadRecipients() {
      this.upload_recipients = !this.upload_recipients;
    },
    showInstructionsRecipients() {
      this.show_instrictions_recipients = !this.show_instrictions_recipients;
    },
    showUnLines() {
      this.show_unLines = !this.show_unLines;
    },

    showUnassignedDetail(centre_id, centreName) {
      const self = this;
      this.groupTitle = centreName;
      self.centresIdUnassigned = centre_id;
      self.getDetails();
    },

    async getDetails() {
      const self = this;
      this.detailLines = [];

      await this.axios({
        method: "get",
        url:
          `https://api.expertel.ca/api/v1/client/me/manager_centres/` +
          self.centresIdUnassigned,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          response.data.lines.map(function(value) {
            self.detailLines.push({
              name: `${value.name}`,
              phone: value.phone_number
            });
          });
        })
        .catch(error => this.makeToast("ERROR",error, "danger"));
      this.showDetails = true;
    },
    closeDetails() {
      this.showDetails = false;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    onChangeManagers() {
      this.file_managers = this.$refs.file_managers.files[0];
    },
    uploadCostCentreListManagers() {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      formData.append("list", self.file_managers);

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/manager_centres/upload`,
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR", response.data.errors, "danger");
            self.loading = false;
          } else {
            self.makeToast("Ok", "List uploaded successfully", "success");
            self.getManagersList();
            self.file_managers = "";
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    },
    async getManagersList() {
      const self = this;
      //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/manager_centres`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.manager_centres_data.manager_centres =
            response.data.manager_centres;

          //Get all data again
          self.recipients_centres_data = [];
          self.manager_centres_data.manager_centres.forEach(function(value) {
            value.admins.forEach(function(value_data) {
              if (value_data.name != "") {
                self.recipients_centres_data.push({
                  name: value_data.name,
                  email: value_data.email
                });
              }
            });
          });
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    onChangeRecipients() {
      this.file_recipients = this.$refs.file_recipients.files[0];
    },
    uploadCostCentreListRecipients() {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      formData.append("file", self.file_recipients);

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/manager_centres/admins/upload`,
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR",response.data.errors, "Error in file");
            self.loading = false;
          } else {
            self.makeToast("Ok", "List uploaded successfully", "success");
            self.getRecipientsList();
            self.file_recipients = "";
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    },
    async getRecipientsList() {
      const self = this;
      //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/manager_centres`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.manager_centres_data.admins = response.data.admins;
        })
        .catch(error => this.makeToast("ERROR",error, "danger"));
    },

    changeReport(e) {
      if (e.target.options.selectedIndex > -1) {
        let id_report = e.target.options[e.target.options.selectedIndex].value;
        const resolved = this.$router.resolve({
          name: "Managers",
          params: { id: id_report }
        });
        history.pushState({}, null, resolved.href);
        this.getSubReportsManagers(id_report);
      }
    },

    getSubReportsManagers(id_report) {
      const self = this;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id_report}/manager_reports`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          let data_centres = [];
          response.data.reports.forEach(function(value) {
            //console.log(value);
            data_centres.push({
              id: value.id,
              pdf_url: value.pdf_url,
              centre_id: value.manager_centre_id,
              centre_name: value.manager_name,
              count: value.num_active
            });
          });
          self.manager_centres_data_filtered = {
            manager_centres: data_centres
          };
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    format(input) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun ",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let date = input.split("-");
      let month = parseInt(date[1]) - 1;
      //let day = date[2];
      let year = date[0];
      return `${months[month]}, ${year}`;
    },

    downloadPdfReport() {
      let selected = this.selected;
      let filesForDownload = [];
      this.manager_centres_data_filtered.manager_centres.forEach(function(
        value
      ) {
        if (selected.indexOf(value.centre_id) != -1) {
          if (value.pdf_url != "") {
            filesForDownload.push(value.pdf_url);
          }
        }
      });

      if (filesForDownload.length > 0) {
        for (let i = 0; i < filesForDownload.length; i++) {
          setTimeout(
            function(path) {
              window.location = path;
            },
            200 + i * 200,
            filesForDownload[i]
          );
        }
      } else {
        this.makeToast(
          "ERROR",
          "Select at least one group to download pdf file",
          "danger"
        );
      }
    }
  },
  computed: {
    selectAll: {
      get: function() {
        return this.manager_centres_data_filtered.manager_centres
          ? this.selected.length == this.manager_centres_data_filtered.length
          : false;
      },
      set: function(value) {
        var selected = [];
        console.log(value);
        if (value) {
          this.manager_centres_data_filtered.manager_centres.forEach(function(
            recomendation
          ) {
            console.log(recomendation.centre_id);
            selected.push(recomendation.centre_id);
          });
        }

        this.selected = selected;
      }
    },

    list_report: function() {
      return this.reports.filter(item => item.parent_id == 0);
    }
  }
};
</script>
